//libraries
import React, { useEffect, lazy, Suspense } from "react"
import { BrowserRouter as Router, Switch, Route, useLocation, Redirect } from "react-router-dom"
import Cookies from 'universal-cookie'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import ReactGa from 'react-ga'

//global state
import { cart, login as ll, shopId, coordinates } from '../store/atoms'

//css
import '../assets/css/Shop.css'
import { getCoord } from '../store/axiosInstance'
import { FetchCart as fc } from '../Methods/Cart'
//components and pages
// import ParticularShop from "./ParticularShop"
const Home = lazy(() => import("./Home"))
const Checkout = lazy(() => import("./Checkout"))
const Review = lazy(() => import("./Review"))
const Profile = lazy(() => import('./Profile.js'))
const BecomeVendor = lazy(() => import('./BecomeVendor.js'))
const Pops = lazy(() => import('../Pops'))
const Login = lazy(() => import('./Login.js'))
const Signup = lazy(() => import("./Signup"));
const SignupDetails = lazy(() => import('./SignupDetails'))
const AfterOrder = lazy(() => import('./AfterOrder'))
const NotFound = lazy(() => import('./NotFound'))
const Footer = lazy(() => import('../components/shop/footer'))
const Payment = lazy(() => import('../components/shop/Payment'))
const VendorDetail = lazy(() => import('./VendorDetail'))
//instantiate cookies object to work with cookies
const cookies = new Cookies();


//scrolling to the top when new component loads
//default preserve of scroll is handled by chrome
function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}


function Shop() {


    //global state
    const globalCart = useSetRecoilState(cart)
    const setLogin = useSetRecoilState(ll)
    const setShopId = useSetRecoilState(shopId)
    const setCoordinates = useSetRecoilState(coordinates)

    //checking for cart stored in cookies
    // if (cookies.get('cart')) {
    //     globalCart(cookies.get('cart'))
    // }
    if (cookies.get('login')) {
        setLogin(true)
    }
    async function fetchCart() {
        const res = await fc()
        if (res && res.data && res.data.data && res.data.data.items) {
            console.log("fc", res.data.data);
            globalCart(res.data.data.items)
            setShopId(res.data.data.seller_id)
        }
    }
    if (getCoord()) {
        console.log("coordinate read from cookies");
        setCoordinates(getCoord())
    }

    useEffect(() => {
        ReactGa.initialize('UA-173070759-1')
        fetchCart()
    }, [])

    return (
        <main>

            <Router>
                <Suspense fallback={<div>Loading...</div>}>
                    <ScrollToTop />
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path='/shop/:id' component={VendorDetail} />
                        <Route path='/login' component={Login} />
                        <Route path='/signup' exact component={Signup} />
                        <Route path='/signupdetails' component={SignupDetails} />
                        <Route path='/vendor' component={BecomeVendor} />
                        <ProtectedRoute path='/order/payment'>
                            <Payment />
                        </ProtectedRoute>
                        <ProtectedRoute path='/order/result'>
                            <AfterOrder />
                        </ProtectedRoute>
                        <ProtectedRoute path='/profile/:tab'>
                            <Profile />
                        </ProtectedRoute>
                        <ProtectedRoute exact path='/profile'>
                            <Profile />
                        </ProtectedRoute >
                        <ProtectedRoute path='/checkout/review'>
                            <Review />
                        </ProtectedRoute>
                        <ProtectedRoute exact path='/checkout'>
                            <Checkout />
                        </ProtectedRoute>
                        <Route component={NotFound} />
                    </Switch>
                    <Route component={Footer} />
                </Suspense>
            </Router>

            <Pops />
        </main>
    )

}
function ProtectedRoute(comp) {
    console.log("Route comp", comp);
    const { children, ...rest } = comp
    const login = useRecoilValue(ll)

    return (
        <Route
            {...rest}
            render={
                (
                    { location }
                ) =>
                    login
                        ? children
                        : <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location }
                            }}
                        />
            }
        />
    )

}



export default Shop