import { atom } from "recoil"

const cart = atom({
    key: 'cart',
    default: []
})

const shopId = atom({
    key: 'shopId',
    default: null
})

const cartPrice = atom({
    key: 'cartPrice',
    default: 0
})

const address = atom({
    key: 'address',
    default: []
})
const selectedAddress = atom({
    key: 'seletedAddress',
    default: {}
})

const addToEdit = atom({
    key: 'addToEdit',
    default: {}
})

const location = atom({
    key: 'location',
    default: ''
})

const coordinates = atom({
    key: 'cooridnates',
    default: {}
})
const pinCode = atom({
    key: 'pinCode',
    default: null
})
const profileData = atom({
    key: 'profileData',
    default: {}
})
const login = atom({
    key: 'login',
    default: false
})
const popups = atom({
    key: 'popups',
    default: ''
})
const mapCenterCoords = atom({
    key: 'mapcentercoords',
    default: {
        lat: 30,
        long: 80
    }
})
const isNumberGiven = atom(
    {
        key: 'number Entered',
        default: ''
    }
)
const receivingMethod = atom(
    {
        key: 'receiving Method',
        default: 'pickup'
    }
)
const paymentMethod = atom(
    {
        key: 'payment Method',
        default: 'cod'
    }
)
const shopCategory = atom(
    {
        key: 'shopCategory',
        default: 'all'
    }
)
const isDirectAccess = atom({
    key: "isDirectAccess",
    default: true
})
export { shopId, addToEdit, isDirectAccess, shopCategory, mapCenterCoords, paymentMethod, receivingMethod, cart, cartPrice, coordinates, address, location, pinCode, selectedAddress, profileData, login, popups, isNumberGiven }
