import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const axiosInstance = axios.create({
    baseURL: "http://ec2-34-207-189-161.compute-1.amazonaws.com",
    headers: { "x-api-key": cookies.get('x-api-key') || '' },
})

function setKey(key) {
    // console.log("in instance key", key);
    if (!cookies.get('x-api-key')) {

        //getting date
        const date = new Date()
        const currentDate = date.getDate()
        //setting the date of  days ahead
        date.setDate(currentDate + 30)

        cookies.set('x-api-key', key, { expires: date })
    }
}
function delKey() {
    cookies.remove('login')
}
function setCoord(coord) {
    cookies.set('coord',coord)
}
function getCoord() {
    const read = cookies.get('coord')
    console.log("this is read",read);
    return read
}
function setLoginC() {
    const date = new Date()
    const currentDate = date.getDate()
    //setting the date of  days ahead
    date.setDate(currentDate + 30)

    cookies.set('login',true,{expires:date})
}

export default axiosInstance
export { setKey, delKey,setLoginC,setCoord,getCoord }
