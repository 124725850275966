import Axios from '../store/axiosInstance';
import Cookies from 'universal-cookie'

const cookies = new Cookies();

async function UpdateCart(cart,sellerId) {
    try {
        //creating new date object and getting todays date
        const date = new Date()
        const currentDate = date.getDate()
        //setting the date of 7 days ahead
        date.setDate(currentDate + 7)
        //setting cookie with expiration of seven days
        cookies.set('cart', JSON.stringify(cart), { expires: date, path: '/' })
        console.log("type of",typeof(cart));
        const response = await Axios.post('/updatecart', { cart:cart, seller_id: sellerId??'' })
        console.log("Cart Updated", response)
    }
    catch (error) {
        if (error.response && error.response.status === 404) {
            console.log("404 in cart")
        }
        console.log("Error in cart updating", error)
    }

}
function clearCart() {
    cookies.remove('cart')
}

async function FetchCart() {
    try {
        const response = await Axios.get('/mycart')
        console.log("Cart Fetched", response.status, response, 'background: green; color: white; display: block;')
        return response
    }
    catch (error) {
        console.log("Error in cart Fetching", error.response)
    }
}

export {clearCart, UpdateCart, FetchCart }