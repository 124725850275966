import React from 'react';
import { RecoilRoot } from "recoil"
//components and css
import Shop from './pages/Shops'
import './assets/css/App.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
// import './assets/css/fontawesome.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import AOS from "aos";
import 'aos/dist/aos.css'

function App() {


  AOS.init();


  return (
    <RecoilRoot>
      <div className="App">
        <Shop />
      </div>
    </RecoilRoot>
  );
}

export default App;
